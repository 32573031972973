import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutMainScreen from "../components/Pages/AboutPage/MainScreen";

import useLanguage from "../components/Hooks/useLanguage";
import AboutPage from "../components/Pages/AboutPage/AboutPage";

const Company = ({ data }) => {
  const langToggle = useLanguage;
  const seo = data.strapiCompany.seo;
  const about = data.strapiHome.About;
  const bg = data.strapiCompany.image;
  return (
    <Layout>
      <SEO
        title={langToggle(about.title_ua, about.title_ru, about.title_en)}
        description={langToggle(
          seo.seo_description_ua,
          seo.seo_description_ru,
          seo.seo_description_en
        )}
        keywords={[
          langToggle(
            seo.seo_keywords_ua,
            seo.seo_keywords_ru,
            seo.seo_keywords_en
          ),
        ]}
      />
      <AboutMainScreen dataMainScreen={data.strapiHome.About} bg={bg} />
      <AboutPage content={data.strapiCompany} />
    </Layout>
  );
};

export default Company;

export const query = graphql`
  query AboutQuery {
    strapiHome {
      About {
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    strapiCompany {
      seo {
        seo_description_ua
        seo_description_ru
        seo_description_en
        seo_keywords_ua
        seo_keywords_ru
        seo_keywords_en
      }
      title_ua
      title_ru
      title_en
      content_ua
      content_ru
      content_en
      image {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
