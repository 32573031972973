import React from "react";

import useLanguage from "../../Hooks/useLanguage";

const ReactMarkdown = require("react-markdown");

const AboutPage = ({ content }) => {
  const langToggle = useLanguage;
  return (
    <section className="section-about">
      <div className="container-fluid">
        <div className="about-content">
          <div className="row">
            <div className="col-12">
              <h3 className="h3">
                {langToggle(
                  content.title_ua,
                  content.title_ru,
                  content.title_en
                )}
              </h3>
              <ReactMarkdown
                source={langToggle(
                  content.content_ua,
                  content.content_ru,
                  content.content_en
                )}
                className="content"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
