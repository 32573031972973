import React from "react";
import Img from "gatsby-image";
import { scroller } from "react-scroll";
import Drop from "../../../svg/drop.svg";
import useLanguage from "../../Hooks/useLanguage";

const AboutMainScreen = ({ dataMainScreen, bg }) => {
  const langToggle = useLanguage;
  function scrollToSection() {
    scroller.scrollTo("section-about", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <section className="home-mainscreen">
      <Img fluid={bg.childImageSharp.fluid} className="main-screen_bg" />
      <div className="main-screen_center">
        <h1 className="h1">
          {langToggle(
            dataMainScreen.title_ua,
            dataMainScreen.title_ru,
            dataMainScreen.title_en
          )}
        </h1>
      </div>

      <button className="scroll-down" onClick={scrollToSection}>
        <Drop />
      </button>
    </section>
  );
};

export default AboutMainScreen;
